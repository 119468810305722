import { Fragment} from "react";
import Head from "next/head";

import Hero from '../components/UI/Hero';
import Works from '../components/UI/Works';
import About from '../components/UI/About';
import Services from '../components/UI/Services';
import Connect from '../components/UI/Connect';

export default function Home() {
  return <Fragment>
      <Head><title>molotuff - Translating emerging technologies into meaningful impact</title> </Head>
      <Hero />
      <Works />
      <Services />
      <About />
      <Connect />
    </Fragment>
}