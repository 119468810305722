import { React, Fragment } from "react";
import { Container, Row, Col, Fade} from 'reactstrap';
import Link from "next/link";
import SectionSubtitle from "./SectionSubtitle";
import classes from "../../styles/connect.module.css";
import FadeInSection from '../FadeInSection/FadeInSection';


const Connect = () => {
    return <section id="real-connect" className={`${classes.connect}`}>
        <Container>
            <Row className="section_divider">
                <SectionSubtitle subtitle="Connect" />
            </Row>
            <Row className={`${classes.connect__contact_container}`}>
                <Col className={`${classes.connect__contact} col-12 col-sm-12 col-md-4`}>
                    <FadeInSection>
                        <h3>Office</h3>
                        <p>
                            <b>Molotuff ApS</b><br />
                            Bredgade 30<br />
                            1260 Copenhagen-DK<br />
                        </p>
                    </FadeInSection>
                </Col>
                <Col className={`${classes.connect__contact} col-12 col-sm-12 col-md-4`}>
                <FadeInSection>
                        <h3>Inquiries</h3>
                        <p>
                            <Link href={'mailto:tony@molotuff.com'}>
                                tony@molotuff.com
                            </Link><br />
                            <span>+45 4112 1609</span>
                        </p>
                        </FadeInSection>
                </Col>
                <Col className={`${classes.connect__contact} col-12 col-sm-12 col-md-4`}>
                <FadeInSection>
                        <h3>Linkedin</h3>
                        <p>
                            <Link href={'https://www.linkedin.com/in/tonygjerlufsen/'}>
                                Tony Gjerlufsen
                            </Link>
                        </p>
                        </FadeInSection>
                </Col>
            </Row>
        </Container>
        <div id="connect"></div>
    </section>
}

export default Connect;