import React from "react";
import { Container, Row, Col} from 'reactstrap';
import Image from "next/image";
import Link from "next/link";
import FadeInSection from '../FadeInSection/FadeInSection';
import SectionSubtitle from "./SectionSubtitle";
import classes from '../../styles/about.module.css';

const About = () => {
    return <section id="about" className={`${classes.about}`}>
        <Container>
            <Row className="section_divider">
                <SectionSubtitle subtitle="About" />
            </Row>
            <Row>
                <Col className="col-lg-4 col-md-6 col-12">
                    <FadeInSection>
                        <p>Molotuff is the independent creative technology studio of Tony Gjerlufsen.</p>
                        <p>Tony is an innovation leader with a long track record of translating emerging technologies into meaningful impact.</p>
                        <p>He is a computer scientist, former creative agency COO, large scale event production director, and tech startup founder, that has spent two decades working with emerging technologies.</p>
                        <p>As a trailblazer and lateral thinker with a deep insight into creative technology and design, his focus is on the creative technology funnel, from research and insight, to impactful products services and experiences.</p>
                        <p>He has spent more the half of the past decade innovating and building products on the edge, as Head of Technology, at IKEA’s R&D lab SPACE10.</p>
                    </FadeInSection>
                </Col>
                    <Col className="col-lg-8 col-md-6 col-12 order-first order-md-last">
                        <FadeInSection>
                            <div className={`${classes.about__image_container}`}>
                                <Image className={`${classes.image_rounded}`} alt="about-img" src={'/images/tony.jpg'} width="0" height="0" sizes="100vw" style={{ width: '100%', height: 'auto' }}/>
                            </div>
                        </FadeInSection>
                    </Col>
            </Row>
        </Container>
    </section>
};

export default About;
