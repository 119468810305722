
const hero = [
    {
        path:'/images/hero/hero2.jpg',
        w: 1440,
        h: 810,
        credits: 'SPACE10 / Carbon Banks. Visuale: Zünc',
    },
    {
        path:'/images/hero/hero3.jpg',
        w: 2560,
        h: 1440,
        credits: 'SPACE10 / Everyday Experiments. Partner: Field.io',
    },
    {
        path:'/images/hero/hero5.jpg',
        w: 1440,
        h: 1800,
        credits: 'SPACE10 / Everyday Experiments. Partner: Field.io',
    },
    {
        path:'/images/hero/hero6.jpg',
        w: 1920,
        h: 1080,
        credits: 'SPACE10 / Everyday Experiments. Partner: Tin & Ed',
    },
    {
        path:'/images/hero/hero7.jpg',
        w: 1920,
        h: 1080,
        credits: 'SPACE10 / Carbon Banks. Partner: Zünc',
    },
    {
        path:'/images/hero/hero8.jpg',
        w: 1080,
        h: 1920,
        credits: 'SPACE10 / Everyday Experiments. Partner: CIRG',
    },
    {
        path:'/images/hero/hero1.jpg',
        w: 2392,
        h: 1330,
        credits: 'SPACE10 / Studio',
    },
];

export default hero;