import React, { Fragment, useRef, useEffect } from "react";
import { Row, Col, Fade} from 'reactstrap';
import VideoPlayer from '../VideoPlayer/VideoPlayer';
import FadeInSection from '../FadeInSection/FadeInSection';
import Image from "next/image";

import classes from '../../styles/works.module.css';

const Work = (props) => {

    if (props.index%2 === 0) {

        return <Row className={`${classes.works__work_container}`}>
            <Col className="col-12 col-sm-12 col-md-6 col-lg-8 order-md-1">
                <FadeInSection>
                    <div className={`${classes.works__work_image_container}`}>
                        <Image alt="work-img" src={props.item.hero.uri} width="0" height="0" sizes="100vw" style={{ width: '100%', height: 'auto' }}/>
                        {
                            ('vid' in props.item.hero) ? (
                                <VideoPlayer video={props.item.hero.vid} />
                            ) : (<div></div>)
                        }
                    </div>
                </FadeInSection>
            </Col>
            <Col className="col-12 col-sm-12 col-md-6 col-lg-4 order-md-2 order-first">
                <FadeInSection>
                    <h3>{props.item.project}</h3>
                    <h4>{props.item.subtitle}</h4>
                </FadeInSection>
            </Col>
            <Col className="col-12 col-sm-12 col-md-6 col-lg-8 order-md-3 order-last">
            {
                props.item.media.map((item,index) => (
                    <Row key={index} className={`${classes.works__work_image_container}`}>
                        <FadeInSection>
                            {
                                item.type === 'video' ? (
                                    <div>
                                        <div className={`${classes.video_rounded}`}>
                                            <video autoPlay loop muted playsInline poster={item.poster} alt="work-vid" style={{ width: '100%', height: 'auto' }} className={item.rounded ? `${classes.media_rounded} lazy` : 'lazy'}>
                                                <source data-src={item.uri} type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                ) : (
                                    <Fragment>
                                        <div>
                                            <Image className={item.rounded ? `${classes.media_rounded}` : ''} alt="work-img" src={item.uri} width="0" height="0" sizes="100vw" style={{ width: '100%', height: 'auto' }}/>
                                            {
                                                ('vid' in item) ? (
                                                    <VideoPlayer video={item.vid} />
                                                ) : (<div></div>)
                                            }
                                        </div>
                                    </Fragment>
                                )
                            }
                        </FadeInSection>
                    </Row>
                ))
            }
            </Col>
            <Col className="col-12 col-sm-12 col-md-6 col-lg-4 order-md-4">
                <FadeInSection>
                    <div dangerouslySetInnerHTML={{ __html: props.item.description }}></div>
                    <p><b>Credits:</b><br/> <span dangerouslySetInnerHTML={{ __html: props.item.credits }}></span></p>
                    <p><b>Contribution:</b> {props.item.role}</p>
                    <p> 
                        <a href={props.item.link} target="_blank" rel="noreferrer">
                            {props.item.link}
                        </a>
                    </p>
                </FadeInSection>
            </Col>
        </Row>

    } else {

        return <Row className={`${classes.works__work_container}`}>
            <Col className="col-12 col-sm-12 col-md-6 col-lg-4">
                <FadeInSection>
                    <h3>{props.item.project}</h3>
                    <h4>{props.item.subtitle}</h4>
                </FadeInSection>
            </Col>
            <Col className="col-12 col-sm-12 col-md-6 col-lg-8">
            <FadeInSection>
                <div className={`${classes.works__work_image_container}`}>
                    <Image alt="work-img" src={props.item.hero.uri} width="0" height="0" sizes="100vw" style={{ width: '100%', height: 'auto' }}/>
                    {
                        ('vid' in props.item.hero) ? (
                            <VideoPlayer video={props.item.hero.vid} />
                        ) : (<div></div>)
                    }
                </div>
            </FadeInSection>
            </Col>
            <Col className="col-12 col-sm-12 col-md-6 col-lg-4">
            <FadeInSection>
                <div dangerouslySetInnerHTML={{ __html: props.item.description }}></div>
                <p><b>Credits:</b><br/> <span dangerouslySetInnerHTML={{ __html: props.item.credits }}></span></p>
                <p><b>Contribution:</b> {props.item.role}</p>
                <p> 
                    <a href={props.item.link} target="_blank" rel="noreferrer">
                        {props.item.link}
                    </a>
                </p>
            </FadeInSection>
            </Col>
            <Col className="col-12 col-sm-12 col-md-6 col-lg-8">
            {
                props.item.media.map((item,index) => (
                    <Row key={index} className={`${classes.works__work_image_container}`}>
                        <FadeInSection>
                    {
                        item.type === 'video' ? (
                            <div>
                                <div className={`${classes.video_rounded}`}>
                                    <video autoPlay loop muted playsInline poster={item.poster} alt="work-vid" style={{ width: '100%', height: 'auto' }} className={item.rounded ? `${classes.media_rounded} lazy` : 'lazy'}>
                                        <source data-src={item.uri} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        ) : (
                            <Fragment>
                                <div>
                                    <Image className={item.rounded ? `${classes.media_rounded}` : ''} alt="work-img" src={item.uri} width="0" height="0" sizes="100vw" style={{ width: '100%', height: 'auto' }}/>
                                    {
                                        ('vid' in item) ? (
                                            <VideoPlayer video={item.vid} />
                                        ) : (<div></div>)
                                    }
                                </div>
                            </Fragment>
                        )
                    }
                        </FadeInSection>
                    </Row>
                ))
            }
            </Col>
        </Row>
    }
};
export default Work;
