import React, { useRef, useState, Fragment} from "react";
import { createPortal } from 'react-dom'
import ReactPlayer from "react-player/lazy";
import classes from "./videoplayer.module.css";

const VideoPlayer = (props) => {
  const playerRef = useRef()
  const [showModal, setShowModal] = useState(false);

  function handleVideoButtonClick(e) {
    e.stopPropagation();
    setShowModal(true);
  }

  function handleVideoModalClose() {
    setShowModal(false);
  }

  return (
    <Fragment>
      <div className={`${classes.videoplayer_play_button_container}`}>
        <button className={`${classes.videoplayer_play_button}`} onClick={(e) => handleVideoButtonClick(e)} />
      </div>
      {showModal && createPortal(
        <div className={classes.videoplayer__player_overlay} onClick={() => handleVideoModalClose()}>
          <div className={classes.videoplayer__player_wrapper}>
            <ReactPlayer
                id="MyId"
                className={classes.videoplayer__react_player}
                width="100%"
                height="100%"
                controls
                config={{
                  vimeo: {},
                  youtube: {}
                }}
                url={props.video}
            ></ReactPlayer>
          </div>
        </div>,
        document.body     
      )}
    </Fragment>
  );
}

export default VideoPlayer;
