
const portfolio = [
    {
        project: 'Carbon Banks',
        subtitle: 'Towards Furniture Circularity Through Digital Assets',
        description: '\
            <p>Transforming how we treat everyday objects. Moving beyond NFTs, Carbon Banks helps us build more sustainable relationships with our belongings and encourage a more circular approach to our furniture.</p>\
            <p>Developed on the back of future forecasting research, it explores positive implications of the hypothesis, that digital originals over time will have a significant impact on peoples\' modes of self expression.</p>\
            <p>It bends the common NFT narrative to fit the mission and brand values of IKEA: Digital self-expression is beyond exclusive monkeys and neon-saturated apparel, scarcity and uniqueness is tied to a mass produced object and accrued over time, and value becomes as much about what you do, as what you pay for.</p>',
        outcome: 'Film, Microsite, 3D assets, Whitepaper',
        credits: 'Concept: SPACE10 & WINT + Anna Schaeffner</br>\
        Creative Direction: Ryan Sherman</br>\
        Project Lead: Sarah Berkowitz</br>\
        CGI: Zünc Studio</br>\
        Whitepaper: Bakken & Bæck',
        role: 'Project Strategy & Direction, Creative Technology Direction.',
        link: 'https://space10.com/projects/carbon-banks',
        hero: {
            type: 'image',
            uri: '/images/portfolio/carbon-banks1.jpg',
            vid: 'https://player.vimeo.com/video/778492846?h=8f5d367c87&badge=0&autopause=0&player_id=0&app_id=58479&autoplay=1"'
        },
        media: [
            {
                type: 'video',
                uri: '/video/carbonbanks/1.mp4',
                poster: '/video/carbonbanks/1-poster.jpg',
                rounded: false
            },
            {
                type: 'video',
                uri: '/video/carbonbanks/2.mp4',
                poster: '/video/carbonbanks/2-poster.jpg',
                rounded: false
            },
            {
                type: 'video',
                uri: '/video/carbonbanks/3.mp4',
                poster: '/video/carbonbanks/3-poster.jpg',
                rounded: false
            },
        ],
    },
    {
        project: 'Everyday Experiments',
        subtitle: 'How will tomorrow\'s technologies redefine the way we live at home?',
        description: '\
            <p>Everyday Experiments is a series of digital experiments that explores the role of emerging technologies in the home.</p>\
            <p>The project operates in the intersection between what can be imagined about tomorrow and what can be operational today. There, it serves a dual purpose – both speculating about the role of technology in the future everyday life at home, and at the same time seeking to validate feasibility using technologies currently available.</p>\
            <p>The format is fast paced experimentation in collaboration with external design partners. The work is based on a thematic brief (two of which were privacy & trust, and sustainability & circularity) along with a set of technological intersections to be explored, identified through internal research.</p>',
        outcome: '40 experiments over 4 themes exploring a wide range of emerging technologies, Website, 2 films',
        credits: 'Project Lead: Georgina McDonald<br/>\
            Creative direction: Kaave Pour, Bas van de Poel, Ryan Sherman<br/>\
            Experiment partners: Alonso Holmes, AlterR, Bakken & Bæck, CIRG, Cream, FIELD.IO, ManvsMachine, Nicole He + Eran Hilleli, Normals, oio, OpenAI, OSK, Philip Pries Henningsen, PITCH STUDIOS, Radical Norms, Random Studio, Set Snail, Snap, Strømlin, Studio Zelle, Timi Oyedeji, Tin & Ed, WINT Design Lab, Yuri Suzuki<br/>\
            Production partner: Bakken & Bæck<br/>\
            Website: Norgram',
        role: 'Project Strategy & Direction, Technology Strategy & Research, Creative Technology Direction.',
        link: 'https://space10.com/projects/everyday-experiments',
        hero: {
            type: 'image',
            uri: '/images/portfolio/everydayexperiments/1.jpg',
            vid: 'https://player.vimeo.com/video/757473639?h=5ef69db2bb'
        },
        media: [
            {
                type: 'video',
                uri: '/video/everydayexperiments/4.mp4',
                poster: '/video/everydayexperiments/4-poster.jpg',
                rounded: false
            },
            {
                type: 'video',
                uri: '/video/everydayexperiments/1.mp4',
                poster: '/video/everydayexperiments/1-poster.jpg',
                rounded: false
            },
            {
                type: 'video',
                uri: '/video/everydayexperiments/5.mp4',
                poster: '/video/everydayexperiments/5-poster.jpg',
                rounded: false
            },
            {
                type: 'video',
                uri: '/video/everydayexperiments/6.mp4',
                poster: '/video/everydayexperiments/6-poster.jpg',
                rounded: false
            },
        ],
    },
    {
        project: 'Studio',
        subtitle: 'What if you could turn your smartphone into a democratic design tool for your home?',
        description: '\
            <p>Studio combines IKEA\'s life at home knowledge with spatial computing, allowing you to measure, style, and share the spaces in your home. It makes the most of your smartphone’s LiDAR sensors to gain detailed 3D depth information of your spaces.</p>\
            <p>Studio was part of the first batch of apps showcasing Apple\'s LIDAR technology, and is a natural evolution of IKEA Place, moving focus from placing furniture in rooms to interior design.</p>\
            <p>Studio comprises a set of features centred around AI driven automation and 3D model interactivity in AR, and has run in closed Beta within a cohort of appx 3.000 users.</p>',
        outcome: 'iOS App, Film, Beta community',
        credits: 'Project lead & creative direction: Tommy Campbell</br>\
            Production: Sarah Berkowitz</br>\
            Technical project lead: Paul Maingot</br>\
            Film: Kühl & Hahn</br>\
            Agency: Bakken & Bæck</br>\
            Sound: Plan8</br>\
            Development: Shape',
        role: 'Technical Excellence & Creative Technology, later Project Strategy & Direction.',
        link: 'https://space10.com/projects/studio',
        hero: {
            type: 'image',
            uri: '/images/portfolio/studio/1.jpg',
            vid: 'https://vimeo.com/536275167/066dbd1e02'
        },
        media: [
            {
                type: 'video',
                uri: '/video/studio/1.mp4',
                poster: '/video/studio/1-poster.jpg',
                rounded: true
            },
            {
                type: 'image',
                uri: '/images/portfolio/studio/2.jpg',
                rounded: true
            },
            {
                type: 'image',
                uri: '/images/portfolio/studio/3.jpg',
                rounded: true
            },
            {
                type: 'video',
                uri: '/video/studio/2.mp4',
                poster: '/video/studio/2-poster.jpg',
                rounded: true
            },
        ],
    },
    {
        project: 'Spaces on Wheels',
        subtitle: 'Exploring a Driverless Future',
        description: '\
            <p>A visual exploration of how fully autonomous vehicles could one day enable a more fulfilling, everyday life.</p>\
            <p>To explore the experience of booking a Space on Wheels, we developed and launched an app where you can go through the full ordering flow, and experience the Space on Wheels in Augmented Reality when it arrives.</p>',
        outcome: 'Seven concept vehicles, IoS App with AR experience, Report',
        credits: 'Creative Direction: Bas Van de Poel, Kaave Pour</br>\
            CGI: f°am Studio</br>\
            Prototype design: Norgram</br>\
            Prototype development: Shape',
        role: 'Creative Technology / AR, Technical Direction: Prototype & Report',
        link: 'https://space10.com/projects/spaces-on-wheels',
        hero: {
            type: 'image',
            uri: '/images/portfolio/spacesonwheels/1.jpg',
            vid: 'https://www.youtube.com/watch?v=fbLXefAG2t4'
        },
        media: [
            {
                type: 'image',
                uri: '/images/portfolio/spacesonwheels/2.jpg',
                vid: 'https://www.youtube.com/watch?v=xtdQO0ZV12Q',
                rounded: false
            },
            {
                type: 'video',
                uri: '/video/spacesonwheels/demo-cut.mp4',
                poster: '/video/spacesonwheels/1-poster.jpg',
                rounded: false
            },
            {
                type: 'image',
                uri: '/images/portfolio/spacesonwheels/3.jpg',
                rounded: false
            },
        ],
    },
    {
        project: 'IKEA Place',
        subtitle: 'Bridging the Imagination Gap With Augmented Reality',
        description: '\
            <p>One of the World\'s best known augmented reality apps, IKEA Place lets you virtually \‘place\’ furnishings in your space. From sofas and lamps, to rugs and tables, you can make sure it\’s just the right size, design and functionality for your room.</p>\
            <p>Conceptualised, developed and fully operated as a digital product within SPACE10 for 4 years. </p>\
            <p></p>',
        outcome: 'iOS App globally deployed and live in 40+ markets, +4M downloads',
        credits: 'Design: Norgram/Barkas</br>\
            Production+video: Barkas</br>\
            Software development: Twnkls/PTC/Shape</br>',
        role: 'Creative Technology, R&D & Technical Excellence, later Product Owner, later Project Direction',
        link: 'https://space10.com/projects/ikea-place',
        hero: {
            type: 'image',
            uri: '/images/portfolio/place/1.jpg',
            vid: 'https://www.youtube.com/watch?v=vMBTlypMgz8'
        },
        media: [
            {
                type: 'image',
                uri: '/images/portfolio/place/3.jpg',
                rounded: true
            },
            {
                type: 'image',
                uri: '/images/portfolio/place/2.jpg',
                rounded: false
            },
            {
                type: 'video',
                uri: '/video/place/1.mp4',
                poster: '/video/place/1-poster.jpg',
                rounded: false
            },
        ],
    },
    {
        project: 'Röst',
        subtitle: 'Exploring the potential of audio interfaces.',
        description: '\
            <p>Röst is a personal audio guide that\'s present in your ears at all times, keeping you in the moment. Designed to work in your pocket, Röst responds directly to your location, wherever you go, and provides context-specific information and inspiration as you move.</p>\
            <p>Deployed as a 3-month prototype trial at IKEA Museum, the project both explore the UX of audio guides, and the maturity of the, then, new wave of indoor Wifi-based positioning technology.</p>',
        outcome: 'Audio prototype running in Detour (defunct), on-site installation, Film, Whitepaper',
        credits: 'Production: Barkas<br/>\
            Audio & Storytelling: Lydpol<br/>\
            Headphones: AIAIAI',
        role: 'Creative Technology Direction, Project Owner',
        link: 'https://space10.com',
        hero: {
            type: 'image',
            uri: '/images/portfolio/rost/1.jpg',
            vid: 'https://www.youtube.com/watch?v=5wMZkfm5sN0',
        },
        media: [
            {
                type: 'video',
                uri: '/video/rost/1.mp4',
                poster: '/video/rost/1-poster.jpg',
                rounded: false
            },
            {
                type: 'image',
                uri: '/images/portfolio/rost/3.jpg',
                rounded: false
            },
            {
                type: 'image',
                uri: '/images/portfolio/rost/4.jpg',
                rounded: false
            },
        ],
    },
    /*{
        project: 'Past projects',
        subtitle: 'Towards Furniture Circularity Through Digital Assets',
        description: '\
            <p>Transforming how we treat everyday objects. Moving beyond NFTs, Carbon Banks helps us build more sustainable relationships with our belongings and encourage a more circular approach to our furniture.</p>\
            <p>The standard chunk of Lorem Ipsum used since the 1500s is reproduced below for those interested. Sections 1.10.32 and 1.10.33 from "de Finibus Bonorum et Malorum" by Cicero are also reproduced in their exact original form, accompanied by English versions from the 1914 translation by H. Rackham.</p>\
            <p>Concept: SPACE10 & WINT + Anna Schaeffner. Creative Direction: Ryan Sherman. Production: Sarah Berkowitz. CGI: Zünc Studio. Whitepaper: Bakken & Bæck</p>',
        outcome: 'Film, Microsite, 3D assets, Whitepaper',
        role: 'Concept, Creative Technology, Project Strategy & Direction',
        link: 'https://carbonbanks.space10.com',
        media: [
            { //https://www.youtube.com/watch?v=_UuMLyk-Ww0 //https://space10.com/event/trailerpark-io-unfolding-the-world-of-art-design-and-technology/
                type: 'image',
                uri: '/images/portfolio/carbon-banks1.png'
            },
            {
                type: 'image',
                uri: '/images/portfolio/carbon-banks1.png'
            },
            {
                type: 'image',
                uri: '/images/portfolio/carbon-banks1.png'
            },
        ],
    }*/
]

export default portfolio;