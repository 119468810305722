
import React, { useState, useEffect } from 'react';
import Image from "next/image";
import { motion, useMotionValue, animate } from "framer-motion";

import Rellax from "rellax";

import HERO_DATA from "../data/hero";

import classes from "../../styles/hero.module.css";

const Hero = () => {

    useEffect(() => {
        new Rellax(".rellax", {
          speed: 5,
          center: false,
          wrapper: null,
          round: true,
          vertical: true,
          horizontal: false
        });
      }, []);
    
    const [heroIndex, setHeroIndex] = useState(0);
    const progress = useMotionValue(heroIndex);
    useEffect(() => {
    
        const animation = animate(0, 1000, {
            duration: 10,
            onUpdate: latest => {},
            onComplete: () => {
                var newIdx = 0;
                if (heroIndex === HERO_DATA.length - 1) {
                    progress.set(0);
                } else {
                    newIdx = heroIndex + 1;
                }
                setHeroIndex(newIdx);
            }
        });
    
        return () => animation.stop();
      }, [heroIndex]);

    return <div className={`${classes.hero}`} >
        <motion.div className={`${classes.hero__img}`}>
            <div className={`${classes.hero__img_credits_container}`}>
                <Image alt="hero-img" priority src={HERO_DATA[heroIndex].path} width={HERO_DATA[heroIndex].w} height={HERO_DATA[heroIndex].h} style={{position: 'relative', width:'auto', height:'100%'}} />
                <div className={`${classes.hero__img__credits}`}>{HERO_DATA[heroIndex].credits}</div>
            </div>
        </motion.div>
        <div className={`${classes.hero__txt} rellax`}>
            <h1>From emerging technologies <br className="d-none d-md-block" /> <br className="d-block d-md-none" />to meaningful impact</h1>
        </div>
    </div>
}

export default Hero;




