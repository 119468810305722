import React from "react";
import { Container, Row, Col} from 'reactstrap';
import Image from "next/image";
import SectionSubtitle from "./SectionSubtitle";
import FadeInSection from '../FadeInSection/FadeInSection';
import classes from '../../styles/services.module.css';

const Services = () => {
    return <section id="services" className={`${classes.services}`}>
        <Container>
            <Row className="section_divider">
                <SectionSubtitle subtitle="Services" />
            </Row>
            <Row className={`${classes.services__approach}`}>
                <Col className={`${classes.services__subtext_container} col-12 col-sm-12 col-md-6 col-lg-4`}>
                    <div className={`${classes.services__subtext}`}>
                        <h4>Technology is impacting the way we live and connect at an accelerating pace. Molotuff uses the forward momentum of technology in service of a future we’d like to see. We have a hybrid R&D practice at the intersection of potential futures, creative technology and strategic design. We partner with progressive companies to translate emerging technologies into meaningful impact – to uncover new opportunities and to shape connections with and among people.</h4>
                    </div>
                </Col>
                <Col className="col-12 col-sm-12 col-md-6 col-lg-8">
                    <div className={`${classes.services__approach_operatingmodel_container}`}>
                        <div className={`${classes.services__approach_operatingmodel}`}>
                            <img src="/operatingmodel.svg" alt="operating model" className={`${classes.services__approach_img}`} />
                            <div className={`${classes.services__approach_text_top}`}>
                                <span>Research &<br/> Concept</span>
                                <span className={`${classes.hover}`}>What<br/> If?</span>
                            </div>
                            <div className={`${classes.services__approach_text_left}`}>
                                <span>Creative<br/> Technology</span>
                                <span className={`${classes.hover}`}>Will This<br/> Work?</span>
                            </div>
                            <div className={`${classes.services__approach_text_right}`}>
                                <span>Strategic<br/> Design</span>
                                <span className={`${classes.hover}`}>How May<br/> We?</span>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col className="col-12 col-sm-12 col-md-4">
                    <div className={`${classes.services__head}`}>
                        <div className={`${classes.services__head__txt}`}>
                            <b>Research & Concept – what if?</b>
                        </div>
                    </div>
                    <p>Looking ahead: Working with potential technological futures, and elaborating on identified opportunities within at the crossection of speculative design and concept development.</p>
                </Col>
                <Col className="col-12 col-sm-12 col-md-4">
                    <div className={`${classes.services__head}`}>
                        <div className={`${classes.services__head__txt}`}>
                            <b>Creative Technology – will this work?</b>
                        </div>
                    </div>
                    <p>Exploring the immediate: Working with the technologies breaking today to assess maturity, validate hypotheses, and to create prototypes, products, services and experiences.</p>
                </Col>
                <Col className="col-12 col-sm-12 col-md-4">
                    <div className={`${classes.services__head}`}>
                        <div className={`${classes.services__head__txt}`}>
                            <b>Strategic Design – how may we?</b>
                        </div>
                    </div>
                    <p>Laying the tracks: Working with strategic design and execution from the position that they are two sides of the same coin.</p>
                </Col>
            </Row>
        </Container>
    </section>
};

export default Services;
