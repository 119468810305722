import React from "react";
import { Container, Row} from 'reactstrap';
import SectionSubtitle from "../UI/SectionSubtitle";
import PORTFOLIO from "../data/portfolio";
import Work from "./Work";
import classes from '../../styles/works.module.css';

const Works = () => {
    return <section id="works" className={`${classes.works}`}>
        <Container>
            <Row className="section_divider">
                <SectionSubtitle subtitle="Works" />
            </Row>
            {
                PORTFOLIO.map((item,index) => (
                    <Work item={item} index={index} key={index} />
                ))
            }
        </Container>
    </section>
};

export default Works;
